<template>
    <div style="background:#F2F2F2;">
        <div style="background:#fff;max-width:1920px;margin:0 auto;">
            <div style="height: 80px;">
                <div class="d-flex h-100" style="border-bottom: 1px solid #ccc;">
                    <div class="flex-1 h-100 d-flex a-center p-l-lg text-left">
                        <img :src="anJunImg" style="height: 60px" alt="安骏跨境物流">
                    </div>
                    <div class="d-flex a-center text-right p-r-lg">
<!--                      <div class="selectLg">-->
<!--                        <span class="lgCon">选择语言</span>-->
<!--                        <div class="selectCon">-->
<!--                          <div style="margin-bottom: 10px;">-->
<!--                            <span v-if="$i18n.locale === 'en-US'"-->
<!--                                  style="color: #009B4C;"-->
<!--                                  @click="changeLanguage()"-->
<!--                                  class="m-r-md cursor-pointer font-sm">{{ $t('language.CN') }}</span>-->
<!--                          </div>-->
<!--                          <div>-->
<!--                            <span v-if="$i18n.locale === 'zh-CN'"-->
<!--                                  style="color: #009B4C;"-->
<!--                                  @click="changeLanguage()"-->
<!--                                  class="m-r-md cursor-pointer font-sm">{{ $t('language.EN') }}</span>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->

                        <span v-if="hrefStr === -1 && $i18n.locale === 'en-US'"
                              style="color: #009B4C;"
                              @click="changeLanguage()"
                              class="m-r-md cursor-pointer font-sm">{{ $t('language.CN') }}</span>
                        <span v-if="hrefStr === -1 && $i18n.locale === 'zh-CN'"
                              style="color: #009B4C;"
                              @click="changeLanguage()"
                              class="m-r-md cursor-pointer font-sm">{{ $t('language.EN') }}</span>
                        <span class="headerBtn cursor-pointer" @click="toNewWindow(1)">{{ $t('language.returnToHome') }}</span>
                        <span class="headerBtn m-l-md cursor-pointer" @click="toNewWindow(2)">{{ $t('language.register') }}</span>
                    </div>
                </div>
            </div>
            <div style="height: calc(100vh - 160px);overflow-y: auto;">
                <div style="padding: 50px 140px;">
                    <div class="text-center font-bold" style="font-size: 36px;">{{ $t('language.trackQuery') }}</div>
                    <div class="font-bold m-t-sm text-center" style="color: #056F34;font-size: 30px;">
                        <span>TRACK</span>
                        <span class="m-l-md">QUERY</span>
                    </div>
                    <div class="d-flex m-t-md cursor-pointer" style="max-width: 70%;margin-left:auto;margin-right: auto;">
                        <div class="flex-1 text-left font-bold"
                             style="border: 1px solid #056F34;height: 100px;">
                            <textarea :placeholder="$t('language.placeholder')"
                                  name="orderNum"
                                  v-model="valueContainer"
                                  style="width:100%;height:94px;border: 0;outline: 0;resize: none;"></textarea>
                        </div>
                        <div class="d-flex a-center j-center font-bold font-lg"
                             @click="trackQuery()"
                             style="width: 200px;height:100px;background:#056F34;color: #fff;">
                            <span :style="{'letter-spacing': $i18n.locale === 'zh-CN' ? '10px' : 0}">{{ $t('language.submit') }}</span>
                        </div>
                    </div>
                    <div style="max-width: 70%;margin-left:auto;margin-right: auto;margin-top: 40px;">
                        <img :src="mapImg" class="w-100" alt="">
                    </div>
                </div>
            </div>
            <div style="height: 80px;background:#009B4C;" class="font-sm font-white d-flex flex-column a-center j-center">
                <div>@ Copyright 2017 szanjuntrack.Com Rights Reserved</div>
                <div>
                  <span>{{ $t('language.anjun') }}</span>
                  <a class="m-l-sm" target="_blank" href="https://beian.miit.gov.cn">粤ICP备19143526号</a>
                  <a class="m-l-sm" target="_blank" href="https://www.beian.gov.cn">京网备44030702004669</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import anjun from '@/assets/image/trajectory/u25.png';
    import logo from '@/assets/image/trajectory/logo.png';
    import logoPu from '@/assets/image/trajectory/logo_pu.png';
    import mapImg from '@/assets/image/trajectory/guijiimg.png';
    export default {
        name: '',
        data() {
            return {
                anJunImg: anjun,
                mapImg: mapImg,
                valueContainer: '',
                hrefStr: ''
            }
        },
        created() {
          this.hrefStr = window.location.href.indexOf('pu');
          if (this.hrefStr !== -1) {
            this.$i18n.locale = 'pu-PT';
            this.anJunImg = logoPu;
          }
          if (this.$route.query.orderNum) {
                this.valueContainer = this.$route.query.orderNum;
            }
        },
        methods: {
//            点击进入轨迹查询页面
            trackQuery() {
                this.$router.push({name:'trackQuery', params:{orderNum: this.valueContainer}});
            },
//            前往其他页面
            toNewWindow(type) {
                if (type === 1) {
                    window.open('http://www.szanjun.com');
                } else {
                    window.open('http://aj.hushengkj.com/reg.asp');
                }
            },
//            切换语言
            changeLanguage() {
                this.$i18n.locale = this.$i18n.locale === 'zh-CN' ? 'en-US' : 'zh-CN';
                this.anJunImg = this.$i18n.locale === 'zh-CN' ? anjun : logo;
            }
        },
        watch: {}
    }

</script>
<style scoped>
    .headerBtn{
        padding: 10px 30px;
        background: #009B4C;
        border-radius: 20px;
        color: #fff;
        font-size: 12px;
    }
    .selectLg {
      position: relative;
      display: inline-block;
    }
    .selectCon {
      display: none;
      position: absolute;
      top: 20px;
      left: 0;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
    .lgCon {
      border: 1px solid #009B4C;
      border-radius: 3px;
      padding: 3px 5px;
      color: #009B4C;
      font-size: 12px;
      margin-right: 10px;
      cursor: pointer;
    }
    .selectLg:hover .selectCon {
      display: block;
    }
</style>
